/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.com/docs/browser-apis/
*/

// importing wrapper
import React from 'react';
import { Wrapper } from "./src/components/globalWrapper"
import { Helmet } from "react-helmet"
import './src/css/global.css'

// importing fonts
import "@fontsource/raleway/200.css"
import "@fontsource/raleway/400.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/700.css"

// importing sal
import sal from "sal.js"
import "sal.js/dist/sal.css"
export const onRouteUpdate = () => {
  window.scrollTo(0, 0)

  if (!window.sal) {
    window.sal = sal()
    window.sal.active = true
    toggleSal()
  } else {
    if (window.sal.active) {
      window.sal.reset()
    }
    toggleSal()
  }

  function toggleSal() {
    if (window.innerWidth <= 768) {
      if (
        !window.sal.active &&
        !document.body.classList.contains("sal-disabled")
      ) {
        document.body.classList.add("sal-disabled")
      } else if (window.sal.active) {
        window.sal.disable()
        window.sal.active = false
      }
    } else if (!window.sal.active) {
      window.sal.enable()
      window.sal.active = true
    }
  }
}

// turning off scroll restoration
export const shouldUpdateScroll = () => {
  return false
}

// adding page wrapper for global context
const themeName = localStorage.getItem('theme') || 'anycover';
export const wrapPageElement = ({ element, props }) => (
  <Wrapper {...props}>
    <Helmet htmlAttributes={{"data-theme": themeName}}/>
    {element}
  </Wrapper>
)
