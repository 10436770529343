import React from "react"

const LocaleContext = React.createContext()

const Wrapper = ({ children, pageContext: { locale } }) => (
  <LocaleContext.Provider value={{ locale }}>
    {children}
  </LocaleContext.Provider>
)

export { Wrapper, LocaleContext }