module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"quality":100,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"anycover-website","short_name":"anycover","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/shield.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0023a429354c0245dee66be5fcd35d1d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","vi","ms","id","tl","th"],"defaultLanguage":"en","fallbackLanguage":"en","siteUrl":"https://anycover.co","trailingSlash":"always","pages":[{"matchPath":"/:lang?/blog/:slug","getLanguageFromPath":true,"excludeLanguages":["vi","ms","id","tl","th"]},{"matchPath":"/:lang?/merchants/:slug","getLanguageFromPath":true,"excludeLanguages":["vi","ms","id","tl","th"]},{"matchPath":"/:lang?/merchant-case-studies/:slug","getLanguageFromPath":true,"excludeLanguages":["vi","ms","id","tl","th"]},{"matchPath":"/:lang?/merchant/:slug","getLanguageFromPath":true,"excludeLanguages":["vi","ms","id","tl","th"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
